import axios from 'axios';
import { node_terms_and_conditions_endpoint } from '../../../assets/jss/incept-sustainability-variables.jsx';
import { useCallback, useEffect, useState } from 'react';

const useTermsBody = (companyId: number) => {
    const language = localStorage.getItem('preferredLanguage') || 'en';

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<string>('');
    const [isError, setIsError] = useState(false);

    const fetchTerms = useCallback(async () => {
        setIsLoading(true);

        const FETCH_URL = node_terms_and_conditions_endpoint(companyId, language);
        // const apiHandler = new APIHandler<void, TermsAndConditionsResponse>(FETCH_URL);

        try {
            const response = await axios.get(FETCH_URL);
            setData(response.data.data);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [companyId, language]);

    useEffect(() => {
        fetchTerms();
    }, [fetchTerms]);

    return { data, isLoading, isError };
};

export default useTermsBody;

import React, { CSSProperties } from 'react';
import Modal from '../Modal/Modal.jsx';


// @ts-ignore
import loadinggif from "assets/img/loading.gif";
import { useTranslation } from 'react-i18next';
import ActionButton from '../CustomButtons/ActionButton.jsx';
import useTermsBody from './data/useTermsBody';


const pStyle: {
    modalTittle: CSSProperties;
    modalBody: CSSProperties;
} = {
    modalTittle: {
        alignSelf: "flex-start",
    },
    modalBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "auto",
        maxHeight: "60dvh",
    },
};


type Props = {
  showModal: boolean,
  setShowModal: (value: boolean) => void,
  title: string,
  buttonText: string,
  bodyComponent?: React.ReactNode,
  companyId: number,
}

const TermsAndCondition = ({showModal, setShowModal, companyId} : Props) => {
    const {t} = useTranslation();

    const {data, isError} = useTermsBody(companyId)
    return (
      <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop={true}
      >
          <div style={{ maxHeight: "70dvh",  }}>
              <div id="loadinggd2" style={{ display: "none" }}>
                  <img
                      className="responsive"
                      src={loadinggif}
                      width="25"
                      alt=""
                      style={{ marginBottom: "20px" }}
                  />
              </div>
              <h1 style={pStyle.modalTittle}>{t("Terms")}</h1>
              <div
                  style={pStyle.modalBody}
              >
                  {isError ? (
                                <div className='text-center text-red-500'>
                                    {t('Coming soon')}...
                                </div>
                            ) 
                            : (
                                data && (
                                    <>
                                        <div
                                            dangerouslySetInnerHTML={{__html: data}}
                                        />
                                       
                                    </>
                                )
                            )}

                  <ActionButton
                      size="auto"
                      onClick={() => {
                          setShowModal(false);
                      }}
                  >
                      {t("Close")}
                  </ActionButton>
                    </div>
                </div>
            </Modal>
        )
};



export default (TermsAndCondition);
